import React from 'react';
import {Link} from '@mui/material'
import { useSearchParams } from "react-router-dom";


function ConversionButton({
    children,
    fontSize="inherit",
    position="inherit",
    mt=0,
    display="block",
    borderRadius=50,
    p={xs:"20px 10px",md:"20px 20px"},
    textAlign="center",
    target="_blank"
}) {

    
    const [searchParams] = useSearchParams();
    
    const baseUrl = "https://triport.co.jp/form/bpo/payroll"

    let partnerId = searchParams.get("id");
    let campainId = searchParams.get("ca");

    if(partnerId == null){
        partnerId = "8f70b68dfe86450fad06adf41c8f4fb2"
    }

    let targetUrl = `${baseUrl}?p=${partnerId}`;

    if(campainId!=null){
        targetUrl += `&ca=${campainId}`
    }
    
    
    
    
    
    return (
        <Link 
            display={display}
            textAlign={textAlign}
            position={position}
            mt={mt}
            color="#FFFFFF" 
            borderRadius={borderRadius}
            fontSize={fontSize} 
            backgroundColor="#fd615c" 
            border="solid 3px #fd615c" 
            boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px"
            p={p} 
            fontWeight="bold" 
            href={targetUrl} 
            target={target}
            sx={{
                textDecoration:"none",
                "&:hover":{
                    transform:"scale(0.95,0.95)",
                    transition:"transform 0.1s ease",
                },
            }}>{children}</Link>
    );
}

// ConversionButton.defaultProps = {
//     display:"block",
//     textAlign:"center",
//     target:"_blank",
    
//     p:{xs:"20px 10px",md:"20px 20px"},
//     borderRadius:50
// }

export default ConversionButton;