import React from 'react'
import {Box,Stack,Grid} from '@mui/material'

function Merit(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

    const msgList=[
        {tag:<>人材の退職リスク</>,msg1:"給与計算担当者が退職した際の人手不足や、突然業務が滞るなどの経営リスク大",msg2:"給与計算のメイン業務をアウトソースしているため、人材不足や業務ストップなどの退職リスクが軽減"},
        {tag:<>人件費・システム費 などのトータルコスト</>,msg1:"専門知識を持つ人材の人件費や教育コストに加え、システムやソフトウエア利用費などトータルコストがかさむ",msg2:"人材面とシステム面、両方のコストを抑えられる"},
        {tag:<>法令遵守・法改正の情報収集と対応</>,msg1:"定期的に行われる法改正の情報をタイムリーにキャッチし、社内で正しく理解・対応することは難易度が高い",msg2:"法改正の情報をタイムリーにキャッチし、かつ正確に対応することが可能。社員の業務負担が軽減されるだけでなく、対応漏れや違法となる経営リスクも軽減"}
    ]

    return (
        <>
        <Box id="merit" component="section" pt={15} pb={10}>
            
            <Box className='section-content' textAlign="center" >

                <Box component="h1" fontSize={{xs:30,md:40}} fontWeight="bold" textAlign="left" display="inline-block" margin="0 auto">
                    <Box fontSize={{xs:20,md:25}}>自社内での給与計算から</Box>
                    <Box >TRIPORTの給与計算BPOに変えるメリット</Box>
                </Box>

                
                <Grid container mt={5} fontSize={{xs:20,md:25}} fontWeight="bold">
                    <Grid item xs={12} md={2} backgroundColor="#deebf3"></Grid>
                    <Grid item xs={6} md={5} backgroundColor="#efefef" p={2}>
                        <Box fontSize={{xs:20,md:25}}>自社内で</Box>
                        <Box>給与計算をする</Box>
                    </Grid>
                    <Grid item xs={6} md={5} backgroundColor="#136ba8" color="white" p={2}>
                        <Box fontSize={{xs:20,md:25}}>TRIPORTの</Box>
                        <Box>給与計算BPOを活用する</Box>
                    </Grid>
                </Grid>
                {msgList.map((item,index)=>{
                    return(
                    <Grid key={index} container mt={1}>
                        <Grid item xs={12} md={2} backgroundColor="#deebf3" p={2} display="flex" alignItems="center" justifyContent="center" fontWeight="bold" fontSize={{xs:18,md:20}}>{item.tag}</Grid>
                        <Grid item container xs={6} md={5} backgroundColor="#efefef" p={2}>
                            <Stack direction={{xs:"column",md:"row"}} alignItems="center" justifyContent="center" gap={1}>
                                <Box component="img" src={baseResouceUrl+"merit01.png"} alt="Speech Bubble" loading="lazy" maxWidth={80} />
                                <Box flex={1} textAlign="left">{item.msg1}</Box>
                            </Stack>
                        </Grid>
                        <Grid item container xs={6} md={5} backgroundColor="#136ba8" color="white" p={2}>
                            <Stack direction={{xs:"column",md:"row"}} alignItems="center" justifyContent="center" gap={1}>
                                <Box component="img" src={baseResouceUrl+"merit02.png"} alt="Speech Bubble" loading="lazy" maxWidth={80} />
                                <Box flex={1} textAlign="left" >{item.msg2}</Box>
                            </Stack>
                        </Grid>
                    </Grid>
                    )
                })}
                

            </Box>
            
        </Box>

        

        
        </>
    )
}

export default Merit;