import React from 'react'
import {Box} from '@mui/material'
import AccentBox from './Components/AccentBox'

function Difference(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

    

    return (
        <>
        <Box id="difference" component="section" pt={15} pb={10} backgroundColor="#deebf3">
            
            <Box className='section-content' textAlign="center" >

                <Box component="h1" fontSize={{xs:30,md:40}} fontWeight="bold" textAlign="center">他社サービスとの違い</Box>

                <Box component="h2" mt={5} fontSize={{xs:16,md:20}} textAlign="center">
                    <Box>TRIPORTの給与計算BPOは、<AccentBox fontWeight="bold">労務のプロ集団による高度な専門性</AccentBox> による付加価値提供をしながらも、</Box>
                    <Box><AccentBox fontWeight="bold">ITのチカラを活用し、極限までヒューマンエラーが起こらないしくみを構築</AccentBox> しています。</Box>
                </Box>

                <Box mt={5} p={{xs:0,md:5}} backgroundColor="white">
                    <Box component="img" src={baseResouceUrl+"difference01.png"} alt="Speech Bubble" loading="lazy" maxWidth="100%" />
                </Box>
                

            </Box>
            
        </Box>

        

        
        </>
    )
}

export default Difference;