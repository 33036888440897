import React from 'react';
import {Box} from '@mui/material'



function AccentBox(props) {

    return (
        <Box
            component="span"
            // fontSize={props.fontSize}
            // fontWeight={props.fontWeight}
            
            // m={props.m}
            // ml={props.ml}
            // mr={props.mr}
            // mt={props.mt}
            // mb={props.mb}
            // p={props.p}
            // pl={props.pl}
            // pr={props.pr}
            // pt={props.pt}
            // pb={props.pb}
            sx={{
                backgroundImage:"URL("+process.env.PUBLIC_URL+"/static/media/Lp/gradation.png)",
                backgroundSize:"cover",
                backgroundRepeat:"round"
            }}
            {...props}
        >{props.children}</Box>
    );
}

// AccentBox.defaultProps = {
//     component:"span",
//     sx:{
//         backgroundImage:"URL("+process.env.PUBLIC_URL+"/static/media/Lp/gradation.png)",
//         backgroundSize:"cover",
//         backgroundRepeat:"round"
//     }
// }

export default AccentBox;