import React from 'react'
import {Box,Stack} from '@mui/material'

function Worries(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

    const msgList=[
        <>給与計算業務が属人化している…<br/>退職リスク、採用教育コストを<br/>下げたい…</>,
        <>ペーパーレス化や電子化が<br/>なかなかできない…</>,
        <>計算ミス、使用ソフトの<br/>システムエラーなどが多く、<br/>毎月の給与計算が大変…</>,
        <>人件費、システム費がかさんでいる…</>,
        <>法改正に<br/>対応する余裕がない…</>,
        <>多様な人材活用のために<br/>「働き方改革」をすると<br/>給与計算が複雑になり大変…</>,
    ]

    return (
        <>
        <Box id="worries" component="section" sx={
            {
                background:`url(${baseResouceUrl+"worries01.jpg"})`,
                backgroundSize:"cover",
                backgroundPositionY:"center",
                backgroundPositionX:"center",
                backgroundRepeat:"no-repeat",
                
            }}>
            <Box backgroundColor="#808080de">
                <Box className='section-content' textAlign="center" pt={15} pb={10}>

                    <Box component="h1" fontSize={{xs:35,md:45}} fontWeight="bold" color="white">
                        <Box >給与計算に関する、</Box>
                        <Box >こんなお悩み、ありませんか？</Box>
                    </Box>

                    
                    <Stack mt={5} direction={{xs:"column",md:"row"}} flexWrap="wrap" justifyContent="space-evenly" gap={2} rowGap={5} fontWeight="bold" fontSize={{xs:16,md:20}}>
                        {msgList.map((item,index)=>{
                            return(
                                <Stack flexBasis="30%"  key={index}  justifyContent="center" alignItems="center">
                                    <Stack flex={1} width="100%" borderRadius={10} p={1} pt={2} pb={2} backgroundColor="white" justifyContent="center">{item}</Stack>
                                    <Box  width={50} height={30} backgroundColor="white" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)", transform:"translateY(-2px)"}} />
                                </Stack>
                            )
                        })}
                    </Stack>
                </Box>
            </Box>
        </Box>

        <Box component="section" backgroundColor="#deebf3" textAlign="center">
            <Box width={{xs:200,md:400}} height={{xs:50,md:100}} m="0 auto" backgroundColor="#808080" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)",transform:"translateY(-2px)"}} />

            <Box className='section-content' textAlign="center" pt={2} pb={10}>
                <Stack direction={{xs:"column",md:"row"}} alignItems="center" justifyContent="center" gap={2}>
                    <Stack flexBasis="25%" alignItems="center" justifyContent="center">
                        <Box width="100%" component="img" src={baseResouceUrl+"worries02.svg"} alt="Speech Bubble" loading="lazy" maxWidth="200px"/>
                    </Stack>
                    <Box fontSize={{xs:25,md:35}} fontWeight="bold" color="#fd615c">そのお悩み、<br/>TRIPORTの給与計算BPOで解決！</Box>
                    <Stack flexBasis="25%" alignItems="center" justifyContent="center">
                        <Box width="100%" component="img" src={baseResouceUrl+"worries03.svg"} alt="Speech Bubble" loading="lazy" maxWidth="350px"/>
                    </Stack>
                </Stack>
            </Box>
        </Box>

        
        </>
    )
}

export default Worries;