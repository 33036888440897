import './App.css';
import { lazy,useEffect} from 'react';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Header from './Pages/Lp/Components/Header';

import FirstView from './Pages/Lp/FirstView';
import Worries from './Pages/Lp/Worries';
import Features from './Pages/Lp/Features';
import Merit from './Pages/Lp/Merit';
import Difference from './Pages/Lp/Difference';
import Scope from './Pages/Lp/Scope';
import ServiceFlow from './Pages/Lp/ServiceFlow';
import Customer from './Pages/Lp/Customer';
import StartFlow from './Pages/Lp/StartFlow';
import Company from './Pages/Lp/Company';
import Footer from './Pages/Lp/Components/Footer';

const Customer1 = lazy(()=>import('./Pages/Lp/Customers/Customer1.js'));
const Customer2 = lazy(()=>import('./Pages/Lp/Customers/Customer2.js'));
const CustomersFooter = lazy(()=>import('./Pages/Lp/Components/CustomersFooter.js'));
// import Customer1 from './Pages/Lp/Customers/Customer1';
// import Customer2 from './Pages/Lp/Customers/Customer2';
// import CustomersFooter from './Pages/Lp/Components/CustomersFooter';



function App() {

  useEffect(()=>{
    const hashSave = window.location.hash
    window.location.hash = ""
    window.location.hash = hashSave

    // console.log(hashSave)
    
  },[])
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={"/customers/oneup"} element={
            <>
              <Header/>
              <Customer1/>
              <CustomersFooter/>
              <Footer/>
            </>
          }/>
          <Route path={"/customers/widelink"} element={
            <>
              <Header/>
              <Customer2/>
              <CustomersFooter/>
              <Footer/>
            </>
          }/>
          <Route path={"/*"} element={
            <>
              <Header/>
              <FirstView/>
              <Worries/>
              <Features/>
              <Merit/>
              <Difference/>
              <Scope/>
              <ServiceFlow/>
              <Customer/>
              <StartFlow/>
              <Company/>
              <Footer/>
            </>
          }/>
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
