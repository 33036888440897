import React from 'react'
import {Box,Stack} from '@mui/material'
import ConversionButton from './Components/ConversionButton'

function Scope(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

    const creatList=(array=>{
        return(
            <>
                {array.map((item,index)=>{
                    return(
                    <Stack key={index} direction="row" gap={1}>
                        <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                        <Box flex={1} fontSize={20} fontWeight="bold" textAlign="left">
                            <Box>{item}</Box>
                        </Box>
                    </Stack>
                    )
                })}
            </>  
        )
    })

    return (
        <>
        <Box id="scope" component="section" pt={15} pb={10} backgroundColor="white">
            
            <Box className='section-content' textAlign="center" >

                <Box component="h1" fontSize={{xs:30,md:40}} fontWeight="bold" textAlign="center">依頼できる業務内容</Box>

                <Stack mt={5} direction={{xs:"column",md:"row"}} justifyContent="space-evenly" gap={2}>
                    <Stack flexBasis="50%">
                        <Stack direction="row" borderRadius={10} backgroundColor="#136ba8" color="white" justifyContent="center" p={2} alignItems="center" fontWeight="bold" fontSize={{xs:18,md:20}}><Box component="span" fontSize={{xs:25,md:30}} mr={1}>毎月</Box>必要な業務</Stack>
                        <Stack alignItems="center">
                            <Box width={50} height={50} backgroundColor="#deebf3" sx={{clipPath:"polygon(50% 0, 100% 100%, 0 100%)",transform:"translateY(2px)"}}/>
                        </Stack>
                        <Box flex={1} p={{xs:2,md:5}} backgroundColor="#deebf3">
                            <Box fontWeight="bold" fontSize={{xs:20,md:25}}>給与計算代行</Box>
                            <Stack mt={2}>
                                {creatList(["給与明細書＆振込データ作成","住民税振込データ作成","源泉徴収票の作成"])}
                            </Stack>
                        </Box>
                    </Stack>

                    <Stack  flexBasis="50%">
                        <Stack direction="row" borderRadius={10} backgroundColor="#136ba8" color="white" justifyContent="center" p={2} alignItems="center" fontWeight="bold" fontSize={{xs:18,md:20}}><Box component="span" fontSize={{xs:25,md:30}} mr={1}>適宜</Box>必要な業務</Stack>
                        <Stack alignItems="center">
                            <Box width={50} height={50} backgroundColor="#deebf3" sx={{clipPath:"polygon(50% 0, 100% 100%, 0 100%)",transform:"translateY(2px)"}}/>
                        </Stack>
                        <Stack flex={1} alignItems="center" p={{xs:2,md:5}} backgroundColor="#deebf3">
                            <Box fontWeight="bold" fontSize={{xs:16,md:18}}>社会保険の<Box component="span" ml={1} mr={1} fontSize={{xs:20,md:25}}>月額変更届</Box>の作成・提出</Box>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack mt={{xs:2,md:5}} alignItems="center">
                    <Stack direction="row" borderRadius={10} backgroundColor="#136ba8" color="white" justifyContent="center" width={{xs:"100%",md:"50%"}} p={2} alignItems="center" fontWeight="bold" fontSize={{xs:18,md:20}}><Box component="span" fontSize={{xs:25,md:30}} mr={1}>年間</Box>スケジュール業務</Stack>
                    <Stack alignItems="center">
                        <Box width={50} height={50} backgroundColor="#deebf3" sx={{clipPath:"polygon(50% 0, 100% 100%, 0 100%)",transform:"translateY(2px)"}}/>
                    </Stack>
                    <Box flex={1} p={{xs:2,md:5}} backgroundColor="#deebf3">
                        <Stack direction={{xs:"column",md:"row"}} flexWrap="wrap" gap={5} justifyContent="space-evenly">

                            <Stack flexBasis="45%" alignItems="start">
                                <Stack direction="row" borderRadius={10} backgroundColor="#6da6d3" color="white" justifyContent="center" width={150} p={1} alignItems="center" fontWeight="bold" fontSize={{xs:18,md:20}}>4~6月</Stack>
                                <Stack mt={2} flex={1}>
                                    {creatList(["給与改定","健康保険料率・介護保険料率の改定","雇用保険料率の改定","労働保険の年度更新","住民税の年度更新"])}
                                </Stack>
                            </Stack>

                            <Stack flexBasis="45%" alignItems="start">
                                <Stack direction="row" borderRadius={10} backgroundColor="#6da6d3" color="white" justifyContent="center" width={150} p={1} alignItems="center" fontWeight="bold" fontSize={{xs:18,md:20}}>7~9月</Stack>
                                <Stack mt={2} flex={1}>
                                    {creatList(["算定基礎届","賞与計算","賞与支払届","最低賃金の改定","厚生年金保険料率の改定"])}
                                </Stack>
                            </Stack>

                            <Stack flexBasis="45%" alignItems="start">
                                <Stack direction="row" borderRadius={10} backgroundColor="#6da6d3" color="white" justifyContent="center" width={150} p={1} alignItems="center" fontWeight="bold" fontSize={{xs:18,md:20}}>10~12月</Stack>
                                <Stack mt={2} flex={1}>
                                    {creatList(["算定基礎による社会保険料変更","年末調整に基づく各種業務","賞与計算","賞与支払届"])}
                                </Stack>
                            </Stack>

                            <Stack flexBasis="45%" alignItems="start">
                                <Stack direction="row" borderRadius={10} backgroundColor="#6da6d3" color="white" justifyContent="center" width={150} p={1} alignItems="center" fontWeight="bold" fontSize={{xs:18,md:20}}>1~3月</Stack>
                                <Stack mt={2} flex={1}>
                                    {creatList(["税務署へ法定調書の提出サポート","市町村へ給与支払報告書の提出サポート"])}
                                </Stack>
                            </Stack>

                        </Stack>

                        <Box mt={5} textAlign="left">※業種、会社の規定などにより、時期が異なる場合があります</Box>                       
                    </Box>
                </Stack>

            </Box>
            
        </Box>

        <Box component="section" pt={10} pb={10} backgroundColor="#efefef">
            
            <Box className='section-content' textAlign="center" color="white">

                <Stack direction={{xs:"column",md:"row"}} alignItems="center" justifyContent="center" gap={2}>
                    <Box width={250}/>
                    <ConversionButton fontSize={{xs:25,md:30}}>無料オンライン相談</ConversionButton>
                    <Box component="img" src={baseResouceUrl+"scope01.svg"} sx={{transform:"scale(-1, 1)"}} alt="Speech Bubble" loading="lazy" width={250} maxWidth="100%" />
                </Stack>
                
            </Box>
        </Box>

        
        </>
    )
}

export default Scope;