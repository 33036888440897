import React from 'react'
import {Box,Stack} from '@mui/material'
import ConversionButton from './Components/ConversionButton'

function StartFlow(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

   
    
    return (
        <>
        <Box id="startFlow" component="section" pt={15} pb={10} backgroundColor="#deebf3">
            
            <Box className='section-content' textAlign="center" >

                <Box component="h1" fontSize={{xs:30,md:40}} fontWeight="bold" textAlign="center">サービスご利用までの流れ</Box>

                <Stack mt={10} direction={{xs:"column",md:"row"}} justifyContent="center" gap={1}>

                    <Stack flexBasis="25%" alignItems="center" justifyContent="center" position="relative" gap={2}>
                        
                        <Box position="absolute" top={-75} left={10} fontSize={90} fontWeight="bold" zIndex={1} color="#6da6d3">01</Box>
                        
                        <Stack width="100%" backgroundColor="white" alignItems="center" justifyContent="center" borderRadius={5} p={2} gap={2}  height={240}>
                            
                            <ConversionButton position="relative">無料オンライン相談<Box component="img" src={baseResouceUrl+"startFlow02.svg"} sx={{transform:"translate(0%, 30%)"}} alt="Speech Bubble" loading="lazy" position="absolute" width={40} maxWidth="100%"  /></ConversionButton>
                            
                        </Stack>
                            
                        <Box color="#136ba8" fontSize={{xs:25,md:25}} fontWeight="bold">ご予約</Box>
                        <Box flex={1} textAlign="left">「無料オンライン相談」のボタンよりご予約ください。</Box>
                    </Stack>

                    <Stack alignItems="center"  mt={{xs:2,md:12}}>
                        <Box  component="img" src={baseResouceUrl+"startFlow01.svg"} alt="arrow" loading="lazy" sx={{transform:{xs:"rotate(90deg)",md:"rotate(0)"}}}   width={20} maxWidth="100%" />
                    </Stack>

                    <Stack flexBasis="25%" alignItems="center" justifyContent="center" position="relative" gap={2}>
                        
                        <Box position="absolute" top={-75} left={10} fontSize={90} fontWeight="bold" zIndex={1} color="#6da6d3">02</Box>
                        
                        <Stack width="100%" backgroundColor="white" alignItems="center" borderRadius={5} p={5} gap={2} height={240} justifyContent="center">
                            <Box component="img" src={baseResouceUrl+"startFlow03.svg"} sx={{transform:""}} alt="Speech Bubble" loading="lazy" width={150} maxWidth="100%" />
                        </Stack>
                        
                        <Box color="#136ba8" fontSize={{xs:25,md:25}} fontWeight="bold">無料オンライン相談</Box>
                        <Box flex={1} textAlign="left">ご状況をヒアリングの上、貴社に最適なソリューションをご提案＆お見積もりをいたします。</Box>
                    </Stack>

                    <Stack alignItems="center" mt={{xs:2,md:12}}>
                        <Box  component="img" src={baseResouceUrl+"startFlow01.svg"} alt="arrow" loading="lazy" sx={{transform:{xs:"rotate(90deg)",md:"rotate(0)"}}}   width={20} maxWidth="100%" />
                    </Stack>

                    <Stack flexBasis="25%" alignItems="center" justifyContent="center" position="relative" gap={2}>
                        
                        <Box position="absolute" top={-75} left={10} fontSize={90} fontWeight="bold" zIndex={1} color="#6da6d3">03</Box>
                        <Stack width="100%" backgroundColor="white" alignItems="center" borderRadius={5} p={5} gap={2} height={240} justifyContent="center" position="relative">
                            
                            <Box component="img" src={baseResouceUrl+"startFlow04.svg"} sx={{transform:"translateY(0px)"}} alt="Speech Bubble" loading="lazy" width={150} maxWidth="100%" mt={5} />
                            <Box component="img" src={baseResouceUrl+"startFlow05.svg"} alt="Speech Bubble" loading="lazy" width={80} maxWidth="100%" position="absolute" top={35} />
                        </Stack>
                        <Box  color="#136ba8" fontSize={{xs:25,md:25}} fontWeight="bold">お申し込み（任意）</Box>
                        <Box flex={1} textAlign="left">Web上で簡単にお申し込み可能です。</Box>
                    </Stack>

                    <Stack alignItems="center"  mt={{xs:2,md:12}}>
                        <Box  component="img" src={baseResouceUrl+"startFlow01.svg"} alt="arrow" loading="lazy" sx={{transform:{xs:"rotate(90deg)",md:"rotate(0)"}}}   width={20} maxWidth="100%" />
                    </Stack>

                    <Stack flexBasis="25%" alignItems="center" justifyContent="center" position="relative" gap={2}>
                        
                        <Box position="absolute" top={-75} left={10} fontSize={90} fontWeight="bold" zIndex={1} color="#6da6d3">04</Box>
                        <Stack width="100%" backgroundColor="white" alignItems="center" borderRadius={5} p={5} gap={2} height={240} justifyContent="center">
                            <Box component="img" src={baseResouceUrl+"startFlow06.svg"} alt="Speech Bubble" loading="lazy" width={200} maxWidth="100%" />
                        </Stack>
                        <Box color="#136ba8" fontSize={{xs:25,md:25}} fontWeight="bold">サービス開始</Box>
                        <Box flex={1} textAlign="left">給与計算代行をスタートします。</Box>
                    </Stack>
                </Stack>
                
            </Box>
        </Box>

        <Box component="section" sx={
            {
                background:`url(${baseResouceUrl+"startFlow07.jpg"})`,
                backgroundSize:"cover",
                backgroundPositionY:"center",
                backgroundPositionX:"center",
                backgroundRepeat:"no-repeat",
               
            }}>
               
            <Box pt={15} pb={10} backgroundColor="#fffffff2" >
                <Box className='section-content' textAlign="center">
                    <Box component="h1" fontSize={{xs:30,md:40}} fontWeight="bold" textAlign="center">どの業種でもOK！</Box>

                    <Box component="h2" fontWeight="bold">
                        <Box mt={5} fontSize={{xs:20,md:25}}>サービス業、不動産業、建設業、医療業、介護業、IT業、運送業、教育機関、行政<Box component="span" ml={1} fontSize={{xs:18,md:20}}>など</Box></Box>
                        <Box fontSize={{xs:18,md:20}}>どのような業種でも、対応可能です。</Box>
                    </Box>

                    <Box mt={10} component="img" src={baseResouceUrl+"startFlow08.svg"} alt="arrow" loading="lazy" width={800}  maxWidth="100%" />
                </Box>
            </Box>
        </Box>

        <Box component="section" pt={10} pb={10} backgroundColor="#efefef">
            
            <Box className='section-content' textAlign="center" >

                <Stack direction={{xs:"column",md:"row"}} alignItems="center" justifyContent="center" gap={2}>
                    <Box overflow="hidden" width={250} maxWidth="100%">
                        <Box component="img" src={baseResouceUrl+"startFlow09.svg"}  sx={{objectFit:"cover",transform:"translate(4%, 0) "}} alt="Speech Bubble" loading="lazy" width="250%" />
                    </Box>

                    <Box>
                        <Box fontSize={{xs:25,md:30}} mb={2} fontWeight="bold">まずは、お気軽にお問い合わせください！</Box>
                        <ConversionButton display="inline-block" fontSize={{xs:25,md:30}}>無料オンライン相談</ConversionButton>
                    </Box>

                    <Box width={250}/>
                    
                </Stack>
                
            </Box>
        </Box>

        </>
    )
}

export default StartFlow;