import React from 'react'
import {Box,Stack,Link} from '@mui/material'
import { useSearchParams } from "react-router-dom";

function Customer(){

    

    const [searchParams] = useSearchParams();

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }

    const customersList =[
        {
            id:"oneup",
            accountName:"株式会社Oneup",
            imageName:"thumbnail.jpg",
            employee:"69名",
            title:<>バックオフィス業務の負担が軽減＆内製リスクから脱却</>,
        },
        {
            id:"widelink",
            accountName:"ワイド・リンク株式会社",
            imageName:"thumbnail.jpg",
            employee:"26名",
            title:<>給与計算・助成金・行政手続き<br/>専門家が伴走する安心のサポート</>,
        }
        
    ]

    const styledLink=(url,label)=>{
        return(<Box backgroundColor="#FD615C" borderColor="#FD615C" border={3} sx={{
            color:"white",
            textDecoration:"none",
            // "&:hover":{
            //     backgroundColor:"white",
            //     color:"#FD615C"
            // }
            "&:hover":{
                    transform:"scale(0.95,0.95)",
                    transition:"transform 0.1s ease",
                },
            }} fontWeight="bold" textAlign="center" mt={2} p={2} width={200} borderRadius={10}>
            <Box position="relative" sx={{
                "&::after":{
                    content:'"〉"',
                    position:"absolute",
                    right:"0",
                }
            }}>{label===void 0 ? "詳しく見る":label}</Box>
        </Box>)
    }

    return (
        <>
        <Box id="customer" component="section" backgroundColor="#efefef">
            <Box className='section-content' textAlign="center" pt={15} pb={10}>

                <Box component="h1" fontSize={{xs:35,md:45}} fontWeight="bold" >
                    <Box >お客様の声</Box>
                </Box>

                
                <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3} mt={5}>

                    {customersList.map((item,index) => (
                        <Link key={index} display="flex" flex={1} color="inherit" maxWidth={{xs:"100%",md:"30%"}} href={`/customers/${item.id}${queryString}`} sx={{textDecoration:"none"}}>
                            <Stack width="100%" p={2} boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px" borderRadius={5} backgroundColor="white">                
                                
                                <Box backgroundColor="#136BA8" color="white" fontSize={16} fontWeight="bold" p={1} textAlign="left">{item.accountName}</Box>
                                <Box component="img" src={process.env.PUBLIC_URL+`/static/media/Lp/Customers/${item.id}/${item.imageName}`} alt={item.imageName} width="100%"></Box>
                                
                                <Stack flexDirection="row" fontSize={11}  gap={1} mt={2} alignItems="center">
                                    <Box width={70} textAlign="center" backgroundColor="#D1EAFE" fontWeight="bold" p={1}>従業員数</Box>
                                    <Box>{item.employee}</Box>
                                </Stack>
                                
                                <Box flex={1} fontSize={22} fontWeight="bold" borderTop="1px dotted gray" mt={2} pt={1} textAlign="left">{item.title}</Box>
                                <Stack alignItems="center">{styledLink(`/customers/${item.id}${queryString}`)}</Stack>
                            </Stack>
                        </Link>
                    ))}

                </Stack>
            </Box>
        </Box>

        

        
        </>
    )
}

export default Customer;