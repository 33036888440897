import React from 'react';
import {Box,Stack,Link} from '@mui/material'
import { useSearchParams } from "react-router-dom";


function Footer() {


    const baseUrl = process.env.PUBLIC_URL
    const [searchParams] = useSearchParams();

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }

    const style_menu={
        //textDecoration:"none",
        fontSize:15,
        color:"#393939",
    }

   

    return (
        <Box component="footer" bgcolor="#EBEBEB" p={5}>
            <Stack  flexDirection={{sx:"column",md:"row"}} margin="0 auto" justifyContent="space-between" gap={1}>
                
                <Stack gap={2}>
                    <Link sx={style_menu} href={baseUrl+"/"+queryString}>
                        <img src={baseUrl+"/static/media/Logo.png"} alt="logo" width={170}></img>
                    </Link>
                </Stack>

                <Stack flex={1} alignItems={{xs:"start",md:"end"}}>
                    <Stack flex={1} flexDirection={{xs:"column",md:"row"}} gap={{xs:2, md:20}} justifyContent={{xs:"center",md:"end"}}>
                        
                        
                        <Stack gap={1}>
                            <Link sx={style_menu} href="https://triport.co.jp/" target='_blank'>TRIPORTグループHP</Link>
                            <Link sx={style_menu} href="https://triport.co.jp/privacy/" target='_blank'>プライバシーポリシー</Link>
                        </Stack>
                    </Stack>
                    <Box mt={2}>© 2024 TRIPORT Co.,Ltd. All rights reserved.</Box>
                </Stack>

                
            </Stack>
            
        </Box>
    );
}

export default Footer;