import React from 'react'
import {Box,Stack,Grid} from '@mui/material'

function ServiceFlow(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

    const flowBox=(title,bgColor)=>{
        return(
            <Stack width="100%" height="100%" alignItems={{xs:"center",md:"flex-start"}} textAlign={{xs:"center",md:"left"}} justifyContent="center" p={1} backgroundColor={bgColor} sx={{clipPath:{xs:"polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%)",md:"polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%)"}}}>{title}</Stack>
        )
    }
    
    return (
        <>
        <Box id="serviceFlow" component="section" pt={15} pb={10} backgroundColor="white">
            
            <Box className='section-content' textAlign="center" >

                <Box component="h1" fontSize={{xs:30,md:40}} fontWeight="bold" textAlign="center">「毎月必要な業務」の対応の流れ</Box>

                <Stack  direction={{xs:"column",md:"row"}} alignItems={{xs:"center",md:"end"}} justifyContent="center" gap={5}>
                    <Box component="img" src={baseResouceUrl+"serviceFlow01.svg"} alt="Speech Bubble" loading="lazy" width={250} maxWidth="100%" />
                    <Box  flexBasis="60%">
                        <Box p={3} pl={5} pr={5} borderRadius={10} backgroundColor="#136ba8" color="white" textAlign="left" fontSize={18} fontWeight="bold">
                            <Box>・社員数の増減や休職なども考慮しつつ、正しく給与データに反映するのが大変…</Box>
                            <Box>・年末調整などの季節的な業務の手続き方法が毎回うろ覚えで不安…</Box>
                            <Box>・毎年ある社会保険関係の法改正等を正しく理解し、給与計算に反映するのは大変…</Box>
                            <Box mt={2} ml={2} fontSize={{xs:25,md:30}}>そんな業務を、プロにお任せ！</Box>
                        </Box>
                        <Stack alignItems="center">
                            <Box width={50} height={50} backgroundColor="#136ba8" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)",transform:"translateY(-2px)"}}/>
                        </Stack>
                    </Box>
                </Stack>

                <Grid container mt={2} fontWeight="bold" fontSize={{xs:16,md:18}}>
                    <Grid item container xs={6} md={12} color="white" backgroundColor="#deebf3">
                        <Grid item xs={12} md={2} display="flex" justifyContent="center" alignItems="center">
                            <Box component="img" src={baseResouceUrl+"serviceFlow02.png"} alt="Speech Bubble" loading="lazy" width={80} maxWidth="100%" />
                        </Grid>
                        <Grid item container xs={12} md={10} p={{xs:1,md:3}}>
                            <Grid item xs={12} md={2} height={{xs:100}}>　</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>　</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>{flowBox(<>勤怠データ<br/>読み込み</>,"#fd615c")}</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>{flowBox("給与計算","#fd615c")}</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>{flowBox(<>給与明細書・<br/>振込データ等作成</>,"#fd615c")}</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>　</Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={6} md={12} backgroundColor="#6da6d3" >
                        <Grid item xs={12} md={2} display="flex" justifyContent="center" alignItems="center" fontSize={{xs:25,md:30}} color="white">貴社</Grid>
                        <Grid item container xs={12} md={10} p={{xs:1,md:3}}>
                            <Grid item xs={12} md={2} height={{xs:100}}>{flowBox("日々の打刻管理","white")}</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>{flowBox(<>勤怠管理<br/><Box component="span" fontSize={16}>勤怠締め・<br/>勤怠データ作成</Box></>,"white")}</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>　</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>　</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>　</Grid>
                            <Grid item xs={12} md={2} height={{xs:100}}>{flowBox(<>給与明細発行<br/>給与振込</>,"white")}</Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box mt={2} fontSize={12} textAlign="left">※既に導入済みの勤怠管理システムがある場合は、TRIPORTにて給与計算代行する際の運用フローが変わる可能性があります。まずはお気軽にご相談ください。</Box>

            </Box>
            
        </Box>

        </>
    )
}

export default ServiceFlow;