import React from 'react'
import {Box,Stack,Grid} from '@mui/material'
import ConversionButton from './Components/ConversionButton'
import AccentBox from './Components/AccentBox'

function Company(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

   
    
    return (
        <>
        <Box id="company" component="section" pt={15} pb={10} backgroundColor="white">
            
            <Box className='section-content' textAlign="center" >

                <Box component="h1" fontSize={{xs:30,md:40}} fontWeight="bold" textAlign="center">運営会社TRIPORTについて</Box>

                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LogoCorp.png"} alt="Speech Bubble" loading="lazy" width={300} maxWidth="100%" />

                <Box mt={2} fontSize={{xs:20,md:25}} >TRIPORTは、<AccentBox fontWeight="bold">企業と働き手とが幸せになれる労務環境づくり</AccentBox>に取り組んでいます。</Box>
                
                <Grid container backgroundColor="#deebf3" mt={10} p={{xs:3,md:5}}>
                    <Grid item xs={12} md={1} display="flex" alignItems="center" justifyContent="center" fontSize={{xs:18,md:20}}>
                        受賞実績
                    </Grid>
                    <Grid item xs={12} md={11} >
                        <Box fontSize={{xs:30,md:35}} fontWeight="bold">厚生労働省、総務省、東京都等から多数表彰</Box>
                        <Stack mt={5} direction={{xs:"column",md:"row"}} justifyContent="space-evenly" gap={5}>

                            <Stack flexBasis="45%"  backgroundColor="white" alignItems="center" borderRadius={10} p={{xs:2,md:4}} gap={2} position="relative">
                                <Box component="img" src={baseResouceUrl+"company01.svg"} alt="Speech Bubble" loading="lazy" width={{xs:100,md:120}} position="absolute" sx={{transform:"scale(-1, 1)"}} top={-50} left={-20}/>
                                <Stack backgroundColor="#136ba8" width="80%" color="white" borderRadius={10} fontSize={{xs:18,md:20}} fontWeight="bold" p={1}>自社実践働き方改革</Stack>
                                <Box fontSize={{xs:16,md:18}} fontWeight="bold">自社の働き方 に関する 受賞・表彰・認定 等</Box>
                                <Stack mt={2} direction="row" flexWrap="wrap" justifyContent="space-evenly" alignItems="center" rowGap={5}>
                                    {["company02","company03","company04","company05","company06","company07"].map((imageName,index)=>{
                                        return(
                                            <Box key={index} component="img" src={baseResouceUrl+imageName+".png"} alt="Speech Bubble" loading="lazy" width={{xs:"45%",md:"30%"}} maxWidth="100%" />
                                        )
                                    })}
                                </Stack>
                            </Stack>

                            <Stack flexBasis="45%"  backgroundColor="white" alignItems="center" borderRadius={10} p={{xs:2,md:4}} gap={2}>
                                <Stack backgroundColor="#136ba8" color="white" width="80%" borderRadius={10} fontSize={{xs:18,md:20}} fontWeight="bold" p={1}>サービス</Stack>
                                <Box fontSize={{xs:16,md:18}} fontWeight="bold">「高付加価値なサービスを低料金で安定的に提供している」と、第三者機関によって評価</Box>
                                <Box component="img" src={baseResouceUrl+"company08.png"} alt="Speech Bubble" loading="lazy" maxWidth="100%" />
                                <Box fontSize={15} textAlign="left">日本HRチャレンジ大賞は、社労士の付加価値をオンラインで提供する「クラウド社労士コモン」にて受賞。</Box>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container  p={5}>
                    <Grid item xs={12} md={1} display="flex" alignItems="center" justifyContent="center" fontSize={{xs:18,md:20}}>
                        業務提携
                    </Grid>
                    <Grid item xs={12} md={11} >

                        <Stack mt={2} direction="row" flexWrap="wrap" justifyContent="space-evenly" alignItems="center" rowGap={2} columnGap={2}>
                            <Box component="img" src={baseResouceUrl+"company09.png"} alt="Speech Bubble" loading="lazy" width={150} />
                            <Box component="img" src={baseResouceUrl+"company10.png"} alt="Speech Bubble" loading="lazy" width={200} />
                            <Stack direction="row">
                                <Box component="img" src={baseResouceUrl+"company11-1.png"} alt="Speech Bubble" loading="lazy" width={85} />
                                <Box component="img" src={baseResouceUrl+"company11-2.png"} alt="Speech Bubble" loading="lazy" width={70} />
                            </Stack>
                            <Box component="img" src={baseResouceUrl+"company12.png"} alt="Speech Bubble" loading="lazy" width={200} />
                            <Box component="img" src={baseResouceUrl+"company13.png"} alt="Speech Bubble" loading="lazy" width={100} />
                            <Box component="img" src={baseResouceUrl+"company14.png"} alt="Speech Bubble" loading="lazy" width={150} />
                            <Box component="img" src={baseResouceUrl+"company15.png"} alt="Speech Bubble" loading="lazy" width={150} />
                            <Box component="img" src={baseResouceUrl+"company16.jpg"} alt="Speech Bubble" loading="lazy" width={170} />
                            <Box component="img" src={baseResouceUrl+"company17.png"} alt="Speech Bubble" loading="lazy" width={90} />
                            <Box component="img" src={baseResouceUrl+"company18.png"} alt="Speech Bubble" loading="lazy" width={70} />
                            <Box component="img" src={baseResouceUrl+"company19.png"} alt="Speech Bubble" loading="lazy" width={100} />
                            <Box component="img" src={baseResouceUrl+"company20.jpg"} alt="Speech Bubble" loading="lazy" width={110} />
                        </Stack>
                        
                    </Grid>
                </Grid>
                
            </Box>
        </Box>

        <Box component="section" sx={
            {
                background:`url(${baseResouceUrl+"company21.jpg"})`,
                backgroundSize:"cover",
                backgroundPositionY:"center",
                backgroundPositionX:"center",
                backgroundRepeat:"no-repeat",
            }}>
            <Box backgroundColor="#136ba8e8" pt={15} pb={15}>
                <Box className='section-content' textAlign="center" >

                    <Stack direction={{xs:"column",md:"row"}} alignItems="center" justifyContent="center" gap={2} >
                        
                            <Box width={250}></Box>

                            <Box color="white">
                                <Box fontSize={{xs:20,md:25}} fontWeight="bold" >お客様のビジネスや課題について、<br/>しっかりとヒアリングした上でお見積もりさせていただきます。</Box>
                                <Box fontSize={{xs:25,md:30}} mt={2} mb={5}  fontWeight="bold">まずは、お気軽にお問い合わせください！</Box>
                                <ConversionButton display="inline-block" fontSize={{xs:25,md:30}}>無料オンライン相談</ConversionButton>
                            </Box>
                            
                            <Box overflow="hidden" width={250} maxWidth="100%">
                                <Box component="img" src={baseResouceUrl+"company22.svg"}  sx={{objectFit:"cover",transform:"translate(-64%, 0) "}} alt="Speech Bubble" loading="lazy" width="250%" />
                            </Box>
                        
                    </Stack>
                    
                </Box>
            </Box>
        </Box>

        </>
    )
}

export default Company;